/* COPYRIGHT 2021 Michael Maur - any form of reuse requires written consent by the author */

//freshly load css file from server / do not use cache
//to achieve this, a random parameter (date/time) is appended to all file names so that browser treats resource as new

function loadCSSAtEndOfHeader(filename) {
  let versionUpdate = new Date().getTime();
  let cssScript: any = document.createElement("link");
  cssScript.setAttribute("rel", "stylesheet");
  cssScript.type = "text/css";
  cssScript.setAttribute("href", filename + "?v=" + versionUpdate);
  cssScript.async = false;
  //script.defer = true;
  document.head.appendChild(cssScript);
}

loadCSSAtEndOfHeader("css/main.css");

//original html: <--link rel="stylesheet" href="css/main.css" />
